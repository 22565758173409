<template>
  <div class="site">
    <banner />
    <!-- <recommend /> -->
    <categoryGoods />
    <div class="gotop" v-show="gotop" @click="toTop"></div>
  </div>
</template>

<script setup>
import banner from './components/banner/index.vue'
// import recommend from './components/recommend/index.vue'
import categoryGoods from './components/category-goods/index.vue'
import { ref, onMounted} from 'vue'
const gotop = ref(false)

onMounted(() => {
  window.addEventListener('scroll', handleScroll, true)
})

const handleScroll= () => {
  let scrolltop = document.documentElement.scrollTop || document.body.scrollTop
  scrolltop > 30 ? (gotop.value = true) : (gotop.value = false)
}
const toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 10)
}

</script>

<style lang="scss" scoped>
.gotop {
  text-align: center;
  position: fixed;
  width: 38px;
  height: 38px;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  padding: 10px;
  background: url('~@/assets/up.svg') no-repeat;
  background-size: 100% 100%;
  color: #000000;
}
</style>
