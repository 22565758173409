<template>
  <div class="category-area container" ref="category">
    <div class="category-item" v-for="(item, index) in  categoryList " :key="item.id" :data-sortId="item.id"
      :data-index="index" :ref="'category_' + item.id">
      <div class="category-item-title">
        <router-link :to="'/category/' + item.id" >{{ item.title }}</router-link>
      </div>
      <div class="category-item-content">
        <div class="menu-container"
          :style="{ background: 'url(' + item.img + ') no-repeat center', backgroundSize: 'cover' }">
          <el-menu :ref="'menu_' + item.id" :collapse="true" background-color="#845490" text-color="#fff"
            active-text-color="#ffd04b" :default-active="item.items[0].alias"
            @select="((alias) => handleCategorySelect(alias, item.id))">
            <template v-for="(childrenItem, index)  in  item.items">
              <template v-if="index < 5">
                <el-menu-item :index="childrenItem.alias">
                  <template v-if="childrenItem.url">
                    <router-link :to="childrenItem.url ? childrenItem.url : '/category/' + childrenItem.id" >{{
                      childrenItem.title }}</router-link>
                  </template>
                  <template v-else>
                    {{ childrenItem.title }}
                  </template>
                </el-menu-item>
              </template>
            </template>
            <el-menu-item class="view-more-btn" @click="viewMore(item.alias, item.id)">{{ $t('global.checkMore')
            }}>></el-menu-item>
          </el-menu>
        </div>
        <div class="category-goods-list">
          <el-skeleton :throttle="600" :loading="getCategoryGoodsLoading(item.id)">
            <template #template>
              <div class="goods-carousel-group">
                <div class="goods-item" v-for="i in Array(8)">
                  <div class="goods-img">
                    <el-skeleton-item variant="image" class="goods-el-skeleton" />
                  </div>
                  <div class="goods-name">
                    <el-skeleton-item variant="text" class="goods-el-skeleton-name"  />
                  </div>
                </div>
              </div>
            </template>
            <template #default>
              <!-- <el-carousel trigger="click" height="400px" :interval="8000" indicator-position="none"
                @change="((toIndex, fromIndex) => handleCarouselChange(toIndex, fromIndex, item.id))">
                <el-carousel-item v-for="(goodsGroup, index) in  formatCategoryGoodsList(categorySelectedId[item.id])"
                  :key="index"> -->
                  <div class="goods-carousel-group">
                    <div class="goods-item" v-for=" goodsItem in categoryGoodsList[categorySelectedAlias[item.id]] " :key="goodsItem.id"
                      @click.stop="onClickTo(goodsItem.id)">
                      <div class="goods-img">
                        <el-image :src="goodsItem.img" :title="goodsItem.name" fit="contain">
                          <div class="image-slot">{{ goodsItem.name }}</div>
                        </el-image>
                      </div>
                      <div class="goods-name" :title="goodsItem.name">{{ goodsItem.name }}</div>
                      <!-- <div class="goods-price">{{ goodsItem.shop_price }}
                      </div> -->
                    </div>
                  </div>
                <!-- </el-carousel-item>
              </el-carousel> -->
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
    <transition name="el-zoom-in-center">
      <div class="elevator-container">
        <div class="content1" ref="elevator">
          <div v-for="elevatorItem in categoryList" :ref="'elevator_' + elevatorItem.id" :data-elevatorId="elevatorItem.id"
            :class="currentCategoryId == elevatorItem.id ? 'item active' : 'item'" :title="elevatorItem.title"
            @click.stop="handleElevatorItemClick(elevatorItem.id)">
            <div class="icon"
              :style="{ background: 'url(' + elevatorItem.icon + ') no-repeat center', backgroundSize: 'cover' }"></div>
            <div class="title">{{ elevatorItem.title }}</div>
          </div>
        </div>
        <div class="handle-btn">
          <div @click.stop="handleElevatorScroll('up')">
            <el-icon><arrow-up /></el-icon>
          </div>
          <div @click.stop="handleElevatorScroll('down')">
            <el-icon><arrow-down /></el-icon>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { computed, ref, watch, onMounted} from 'vue';
import { goodsSortListAll, goodsList} from '@/api/goods'
import { useRouter } from 'vue-router'
import { ElIcon } from 'element-plus'; // 确保引入 Element Plus 的图标组件
import { ArrowUp, ArrowDown} from '@element-plus/icons-vue';
import _ from 'lodash'

// 定义响应式变量
const categoryList = ref({});
const categoryIdArray = ref([]);
const categorySelectedAlias = ref({});
const loadingGoodsList = ref({});
const watchLoadingGoodsList = ref({});
const categoryGoodsList = ref({});

const loading = ref(true)
const init = () => {
  goodsSortListAll({
    parent_id: 1,
    level: 3,
  }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      const { items } = data
      categoryList.value = items
    }
    // console.log('categoryList',categoryList)
    categoryList.value.forEach(element => {
      // console.log('element.items.value', element.items[0])
      categoryIdArray.value.push(Number(element.id));
      if (element.items && element.items[0]) {
        const alias = element.items[0].alias;
        goodsInit(alias, element.id);
        categorySelectedAlias.value[element.id] = alias;
      }
    });
    // observerCategoryScroll();
    loading.value = false
  })
  .catch(() => {
    loading.value = false
  })
}
init()
const router = useRouter()
const onClickTo = (id) => {
  router.push({
    name: 'GoodsDetail',
    query: { id }
  })
}

const viewMore = (alias, id) => {
  router.push({
    name: 'sort',
    query: { alias, id}
  })
}

watch(watchLoadingGoodsList, (val) => {
  loadingGoodsList.value = { ...val }; // 使用展开运算符来复制对象

  // console.log('loadingGoodsList11',loadingGoodsList.value)
}, { deep: true });

// 计算属性
// const formatMenuIndex = computed(() => {
//   return (val) => {
//     return '' + val;
//   };
// });

// const formatMenuDefaultActiveIndex = computed(() => {
//   return (val) => {
//     return categorySelectedAlias.value[val] === undefined ? '' : categorySelectedAlias.value[val];
//   };
// });

const getCategoryGoodsLoading = computed(() => {
  return (val) => {
    // console.log('loadingGoodsList', val, loadingGoodsList.value[val])
    return loadingGoodsList.value[val];
  };
});

// 处理分类选择
const handleCategorySelect = (alias, parentId) => {
  if (!categoryGoodsList.value[alias] || categoryGoodsList.value[alias].length < 1 ) {
    goodsInit(alias, parentId);
  }
  categorySelectedAlias.value[parentId] = alias;
  // console.log('categorySelectedAlias', categorySelectedAlias)
};

const goodsInit = (alias, parentId, page = 1, pageSize = 8) => {
  const tempData = {
    page,
    pageSize,
    goods_sort_alias: alias,
  }
  goodsList(tempData).then((res) => {
    // console.log('watchLoadingGoodsList',watchLoadingGoodsList);
    const { error, data } = res
    if (error === 0) {
      const { items } = data
      categoryGoodsList.value[alias] = items
      if (items.length > 0) {
        watchLoadingGoodsList.value[parentId] = false;
      }
    }
    // console.log('categoryGoodsList',categoryGoodsList.value)
  })
}

const currentCategoryId = ref(null);
const showElevator = ref(false);

// 创建 refs
const category = ref(null);
const elevator = ref(null);

// 处理电梯项点击
const handleElevatorItemClick = (categoryId) => {
  const categoryElement = document.querySelector(`[data-sortid="${categoryId}"]`);
  const elevatorElement = document.querySelector(`[data-elevatorId="${categoryId}"]`);

  currentCategoryId.value = categoryId;

  if (categoryElement) {
    window.scroll({
      top: categoryElement.offsetTop - 80,
      behavior: 'smooth'
    });
  }

  if (elevatorElement) {
    // console.log('elevator.value22',elevator.value, elevatorElement.offsetTop)
    elevator.value.scroll({
      top: elevatorElement.offsetTop - 158,
      behavior: 'smooth'
    });
  }
};

// 处理电梯滚动
const handleElevatorScroll = (direction) => {
  console.log('categoryIdArray',categoryIdArray, currentCategoryId.value)
  let index = categoryIdArray.value.indexOf(Number(currentCategoryId.value)), nextIndex = 0;
  nextIndex = direction === 'up' ? index - 1 : index + 1;

  if ((direction === 'up' && nextIndex < 0) || (direction === 'down' && (nextIndex >= categoryIdArray.value.length))) {
    return;
  }

  handleElevatorItemClick(categoryIdArray.value[nextIndex]);
};

// 观察分类滚动
const observerCategoryScroll = () => {
  // console.log('observerCategoryScroll')
  const observer = new IntersectionObserver((entries) => {
    // console.log('entries',entries)
    entries.forEach(entry => {
      if (entry.target.dataset.sortId === categoryIdArray.value[0]) {
        // 向下划入
        if (entry.isIntersecting) {
          showElevator.value = true;
        } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
          showElevator.value = false;
        }
      }

      if (entry.isIntersecting) {
        currentCategoryId.value = entry.target.dataset.sortId;

        let childCategoryId = 0, categoryIndex = entry.target.dataset.index;
        if (categoryList.value[categoryIndex].items.length > 0) {
          childCategoryId = categoryList.value[categoryIndex].items[0].id;
          goodsInit(childCategoryId, currentCategoryId.value);
        } else {
          watchLoadingGoodsList.value[currentCategoryId.value] = false;
        }

        const elevatorElement = document.querySelector(`[data-elevatorId="${currentCategoryId.value}"]`);
        if (elevatorElement) {
          elevatorElement.scroll({
            top: elevatorElement.offsetTop - 150,
            behavior: 'smooth'
          });
        }
      }
    });
  }, {
    threshold: [0.8],
  });

  // console.log('category.value.children',category.value.children)

  const children = category.value.children;
  Array.from(children).forEach(item => {
    console.log('item',item)
    observer.observe(item);
  });
};

// 在组件挂载时观察滚动
onMounted(() => {
  // observerCategoryScroll()
});

</script>
<style lang="scss" scoped>
.category-area {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .category-item {
    height: 430px;
    display: flex;
    flex-direction: column;
    margin: 15px 0px;

    .category-item-title {
      height: 25px;
      color:#000;
      line-height: 25px;
      font-size: 22px;
      font-weight: 600;
      padding-left: 15px;
      margin: 5px 2px 10px 2px;
      border-left: 3px solid #de0a0a;
      cursor: pointer;
    }

    .category-item-content {
      height: 400px;
      display: flex;
      border-radius: 5px;
      background-color: #fff;

      .menu-container {
        width: 20%;
        border-radius: 10px;
        background-color: #000;

        :deep(.el-menu) {
          width: 100%;
          height: 90%;
          // border-radius: 10px;
          background-color: transparent !important;

          .el-submenu,
          .el-menu-item {
            display: flex;
            justify-content: center;
            transition: transform .5s ease;
          }

          .el-submenu:hover,
          .el-menu-item:hover {
            font-weight: 600;
            transform: scale(1.1);
          }

          .el-submenu.is-active {
            .el-submenu__title {
              background-color: #de0a0a !important;
              // font-weight: 600;
            }
          }

          .el-submenu:first-child {
            margin-top: 30px;

            .el-submenu__title {
              border-radius: 10px;
            }
          }

          .el-submenu__title {
            // background-color: #845490 !important;
            background: transparent !important;
            padding: 0 30px !important;
          }

          .customer-submenu {
            .el-menu--popup {
              min-width: 180px;
            }
          }

          .el-menu-item {
            width: 70%;
            height: 45px;
            line-height: 45px;
            background: transparent !important;
            padding: 0 30px !important;
            margin: 10px auto;
            text-align: center;
            border-radius: 5px;
            font-size: 18px;
          }

          .el-menu-item:first-child {
            margin-top: 30px;
          }

          .el-menu-item.is-active {
            color: #fff !important;
            background-color: #de0a0a !important;
            // font-weight: 600;
          }

          .view-more-btn {
            background: transparent !important;
            text-align: right;
            margin-right: 10px;
            transition: transform .5s ease;
          }

          .view-more-btn:hover {
            font-weight: 600;
            transform: scale(1.1);
          }
        }
      }

      .category-goods-list {
        width: 80%;
        height: 100%;

        :deep(.el-skeleton) {
          height: 400px;
        }

        .goods-carousel-group {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;

          .goods-item {
            width: calc(25%);
            height: 50%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            padding: 10px 20px;
            box-shadow: inset -3px -3px 5px 2px #e0dada;
            border-radius: 5px;
            cursor: pointer;

            .goods-img {
              width: 100px;
              height: 100px;

              .el-image {
                width: 100%;
                height: 100%;
              }
            }

            .goods-name {
              width: 100%;
              // text-align: center;  
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .goods-price {
              width: 100%;
              font-weight: 600;
              color: #de0a0a;
            }
          }
        }
      }
    }
  }

  .elevator-container {
    width: 80px;
    margin-left: 630px;
    position: fixed;
    left: 50%;
    top: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #de0a0a;
    // visibility: hidden;

    .content1 {
      width: 100%;
      height: 260px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .item {
        margin: 5px;
        padding: 2.5px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          width: 25px;
          min-width: 25px;
          height: 25px;
        }

        .title {
          margin-left: 5px;
          font-size: 16px;
          max-width: calc(100% - 25px);
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }


      .item:not(:last-child) {
        border-bottom: 1px solid #de0a0a;
      }

      .item:hover {
        color: #de0a0a;
        // font-weight: 550;
      }

      .item.active {
        color: #fff;
        background-color: #de0a0a;
        border-bottom: 1px solid #de0a0a;
      }
    }

    .handle-btn {
      font-size: 16px;

      div {
        cursor: pointer;
        margin: 10px 0;
      }
    }

  }

  .elevator-container:visible {
    background-color: #de0a0a;
    transform: scale(1);
  }  
}

a {
  color: #191818;
}

.goods-el-skeleton {
  width: 100px; 
  height: 100px;
}

.goods-el-skeleton-name{
  margin-top: 10px; 
  height: 20px;
}
</style>
