<template>
  <div class="carousel">
    <el-skeleton :throttle="600" :loading="loadingData" animated>
      <template #template>
        <el-skeleton-item variant="image" class="el-skeleton-item" />
      </template>
      <template #default>
        <el-carousel trigger="click" :interval="5000">
          <el-carousel-item v-for="item in lists" :key="item.id">
            <el-image :src="item.img" :title="item.title" fit="cover" @click="onSwiperTo(item)"
              :class="item.url ? 'link' : ''"></el-image>
          </el-carousel-item>
        </el-carousel>
      </template>
    </el-skeleton>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { slideList } from '@/api/app.js'
import { onSlideClickTo } from '@/utils/index.js'
const lists = ref([])
const loadingData = ref(true)
const init = () => {
  slideList({
    slide_position_alias: 'home_banner'
  }).then((res) => {
    const { data } = res
    const { items } = data
    lists.value = items
    loadingData.value = false
    console.log('lists',lists)
  })
}
init()

const onSwiperTo = (item) => {
  onSlideClickTo(item)
}
</script>
<style lang="scss" scoped>
.carousel {
  width: 100%;
  height: 450px;

  :deep(.el-carousel__container) {
    width: 100%;
    height: 450px;
  }

  .el-skeleton-item {
    width: 100%; 
    height: 450px;
  }

  :deep(.el-image) {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
  }

  .el-image.link {
    cursor: pointer;
  }
}

</style>
